export default {
  labels: {
    total: 'Total',
  },
  actions: {
    remove_image: "Supprimer l'image",
  },
  enums: {
    game_entries: {
      id_type: {
        no_type: 'Sans passer par une API GALEC',
        infomil: 'Par API INFOMIL',
        ciam: 'Par API CIAM',
      },
    },
  },
  store_importer: {
    config: {
      separator: 'Caractère de séparation',
      central: 'Colonne contenant le nom de la centrale',
      province: 'Colonne contenant le nom du département',
      store_name: 'Colonne contenant la raison sociale du magasin',
      store_display_name: 'Colonne contenant le nom du magasin',
      store_address: "Colonne contenant l'adresse du magasin",
      store_city: 'Colonne contenant la ville du magasin',
      store_postal_code: 'Colonne contenant le code postal du magasin',
      store_id_number: 'Colonne contenant le code pannonceau du magasin',
    }
  },
  game: {
    graph_title: 'Participations journalières',
    wheel: {
      congratulation: 'Félicitations !',
      win: "<tspan x='287'>Vous venez de remporter</tspan><tspan x='287' dy='28px'>#{cash_prize}</tspan>",
      win_notice: "<tspan x='287'>Vous allez recevoir le détail de votre gain</tspan><tspan x='287' dy='24px'>par mail afin de récupérer votre lot</tspan><tspan x='287' dy='24px'>dans votre magasin E.Leclerc Saint-Médard.</tspan>",
      loss: "C'est perdu !",
      loss_message: "<tspan x='287'>Vous pourrez retenter votre</tspan><tspan x='287' dy='24px'>chance dès demain.</tspan><tspan x='287' dy='24px'>À bientôt !</tspan>",
    },
    config: {
      questions: {
        _label: 'Questions',
        question: 'Libellé de la question',
        answers: {
          _label: 'Réponses',
          label: 'Libellé de la réponse',
          valid_answer: 'Bonne réponse',
        },
      },
      main_grid: {
        _label: 'Grille principale',
        count: 'Nombre de numéros gagnants',
        max: 'Nombre de numéros dans la grille',
        columns: 'Nombre de colonnes dans la grille',
      },
      extra_grid: {
        _label: 'Grille complémentaire',
        enable: 'Utiliser la grille complémentaire',
        count: 'Nombre de numéros gagnants',
        max: 'Nombre de numéros dans la grille',
        columns: 'Nombre de colonnes dans la grille',
      },
      wheel_segments_count: 'Nombre de segments de la roue',
      randomize_wheel_segments: 'Répartir les lots aléatoirement dans la roue',
    },
    graphic_config: {
      home_page: {
        _label: "Bloc page d'accueil",
        logo: 'Logo du jeu',
        background: 'Image de fond',
        background_call_to_play: 'Image de fond appel à participation',
        image_dotation: 'Image de la dotation',
        custom_css: 'CSS personalisé'
      },
      game_specific: {
        _label: 'Paramètres graphiques spécifiques au jeu',
        scratch_image: 'Image à gratter',
        scratch_lost_image: 'Image ticket non gagnant',
        wheel_loss_segment_background: 'Couleur du fond du segment perdu',
        wheel_loss_segment_foreground: 'Couleur du texte du segment perdu',
      },
    },
    already_played: 'Vous avez déjà participé à ce jeu',
    unknown_account: 'Désolé, aucun compte ne correspond à cet email',
  },
  widgets: {
    coordinates: {
      error: "Impossible de récupérer les coordonnées GPS de l'adresse indiquée.",
    },
    validation: {
      blank: 'Ce champ ne peut être vide',
      value_missing: 'Ce champ ne peut être vide',
      invalid_email: 'Saisissez une adresse email valide',
      password_confirmation_doesnt_match: 'La confirmation ne correspond pas au mot de passe saisi',
      bad_input: 'La saisie est invalide',
      pattern_mismatch: 'La valeur saisie ne correspond pas au motif demandé',
      range_overflow: 'La valeur excède la plage autorisée',
      range_underflow: 'La valeur est sous la plage autorisée',
      too_long: 'La valeur est trop longue',
      too_short: 'La valeur est trop courte',
      email_mismatch: "La valeur saisie n'est pas un email valide",
      url_mismatch: "La valeur saisie n'est pas une URL valide",
      unchecked: "Accepter le règlement est obligatoire pour pouvoir participer",
    },
    field_limit: {
      remaining: "Caractères restants : #{count}",
    },
    file_preview: {
      label: "Parcourir…",
    },
    table_header_sort: {
      reset: 'Remettre à zéro le tri',
    },
  },

  settings_input: {
    required: {
      label: 'Champ obligatoire',
    },

    collection: {
      values: {
        label: 'Valeurs possible (liste séparée par des virgules)',
        placeholder: 'item 1, item 2, item 3',
      },

      multiple: {
        label: 'Plusieurs choix possible',
      },
    },

    integer: {
      default: {
        label: 'Valeur par défaut',
        placeholder: '1',
      },

      min: {
        label: 'Valeur minimum',
        placeholder: '0',
      },

      max: {
        label: 'Valeur maximum',
        placeholder: '100',
      },

      step: {
        label: 'Pas',
        placeholder: '1',
      },
    },

    float: {
      default: {
        label: 'Valeur par défaut',
        placeholder: '1',
      },

      min: {
        label: 'Valeur minimum',
        placeholder: '0',
      },

      max: {
        label: 'Valeur maximum',
        placeholder: '100',
      },

      step: {
        label: 'Pas',
        placeholder: '0.1',
      },
    },

    string: {
      limit: {
        label: 'Limite de taille',
        placeholder: 'aucune limite',
      },

      textarea: {
        label: 'Texte multiligne',
      },
    },
  },
  infomil: {
    invalid_card: 'Numéro de carte ou code secret incorrect.',
  },
};
